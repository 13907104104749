<template>
  <base-layout-two page-title="New Guest" :page-default-back-link="`/users`">
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <ion-grid class="ion-no-padding grid-padding">
          <!-- Guest Type Row -->
          <ion-row>
            <ion-col>
              <base-card title="User Type">
                <base-input label-text="Type">
                  <v-field name="role" v-slot="{ field }" v-model="user.role" :rules="required">
                    <select v-bind="field" class="regular-select">
                      <option value="mother">Mother</option>
                      <option value="baby">Baby</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="role" class="error-message"></v-error-message>
              </base-card>
            </ion-col>
          </ion-row>

          <!-- Profile Information -->
          <ion-row v-if="user.role">
            <ion-col>
              <base-card title="Profile Information">
                <ion-grid>
                  <ion-row>
                    <!-- Salutation -->
                    <ion-col size="12" size-lg="2" v-if="user.role !== 'baby'">
                      <base-input label-text="Title">
                        <v-field name="title" v-slot="{ field }" v-model="user.title">
                          <select v-bind="field" class="regular-select">
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Miss">Miss</option>
                            <option value="Mr.">Mr.</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="title" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- First Name -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="First Name *">
                        <v-field name="first_name" v-slot="{ field }" v-model="user.first_name" :rules="required">
                          <ion-input name="first_name" v-bind="field" placeholder="First Name" type="text"
                            autocapitalize="words"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="first_name" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- Last Name -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Last Name *">
                        <v-field name="last_name" v-slot="{ field }" v-model="user.last_name" :rules="required">
                          <ion-input name="last_name" v-bind="field" placeholder="Last Name" type="text"
                            autocapitalize="words"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="last_name" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Pronouns -->
                    <ion-col size="12" size-lg="2">
                      <base-input label-text="Pronouns">
                        <v-field name="pronouns" v-slot="{ field }" v-model="user.profile.pronouns">
                          <select v-bind="field" class="regular-select">
                            <option value="">No preference</option>
                            <option value="She/her">She/her</option>
                            <option value="She/her">She/they</option>
                            <option value="He/him">He/him</option>
                            <option value="She/her">He/they</option>
                            <option value="They/them">They/them</option>
                          </select>
                        </v-field>
                      </base-input>
                    </ion-col>

                    <!-- Alias -->
                    <ion-col size="12" size-lg="2">
                      <base-input label-text="Preferred Name">
                        <v-field name="alias" v-slot="{ field }" v-model="user.alias">
                          <ion-input name="alias" v-bind="field" placeholder="(i.e. Jen or Mrs. Smith)" type="text"
                            autocapitalize="words"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="alias" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>
          </ion-row>

          <!-- Contact Information -->
          <ion-row v-if="user.role && user.role !== 'baby' && user.role !== 'visitor'">
            <ion-col>
              <base-card title="📞 Contact">
                <ion-row>
                  <!-- Email -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Email *">
                      <v-field name="email" v-slot="{ field }" v-model="user.email" :rules="required">
                        <ion-input name="email" v-bind="field" placeholder="Email" type="email"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="email" class="error-message"></v-error-message>
                  </ion-col>

                  <!-- Mobile Number -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Mobile Number">
                      <v-field name="mobile_number" v-slot="{ field }" v-model="user.mobile_number">
                        <ion-input name="mobile_number" v-bind="field" placeholder="555-555-5555"
                          type="tel"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="mobile_number" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>
          </ion-row>

          <!-- Care -->
          <ion-row v-if="user.role && user.role !== 'baby' && user.role !== 'visitor'">
            <ion-col>
              <base-card title="❤️ Care">
                <ion-row>
                  <!-- Due Date -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Due Date">
                      <v-field name="due_date" v-slot="{ field }" v-model="user.profile.due_date">
                        <ion-input name="due_date" v-bind="field" placeholder="" type="date"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="due_date" class="error-message"></v-error-message>
                  </ion-col>

                  <!-- Allergies -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Allergies">
                      <v-field name="allergies" v-slot="{ field }" v-model="user.profile.allergies">
                        <ion-input name="allergies" v-bind="field" placeholder="" type="text" autocapitalize
                          autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="allergies" class="error-message"></v-error-message>
                  </ion-col>

                  <!-- Dietary Preference -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Dietary Preference">
                      <v-field name="dietary_preferences" v-slot="{ field }" v-model="user.profile.dietary_preferences">
                        <select v-bind="field" class="regular-select">
                          <option value="regular">Regular</option>
                          <option value="gluten-free">Gluten-Free</option>
                          <option value="vegetarian">Vegetarian</option>
                          <option value="kosher">Kosher</option>
                        </select>
                      </v-field>
                    </base-input>
                    <v-error-message name="dietary_preferences" class="error-message"></v-error-message>
                  </ion-col>

                  <!-- Prescribed Medication -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Prescribed Medications">
                      <v-field name="prescribed_medications" v-slot="{ field }"
                        v-model="user.profile.prescribed_medications">
                        <ion-input name="prescribed_medications" v-bind="field" placeholder="" type="text"
                          autocapitalize autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="prescribed_medications" class="error-message"></v-error-message>
                  </ion-col>

                  <!-- OTC Medications -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="OTC Medications">
                      <v-field name="otc_medications" v-slot="{ field }" v-model="user.profile.otc_medications">
                        <ion-input name="otc_medications" v-bind="field" placeholder="" type="text" autocapitalize
                          autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="otc_medications" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>
          </ion-row>

          <ion-row v-if="user.role && user.role !== 'baby' && user.role !== 'visitor'">
            <!-- Support Person -->
            <ion-col size="12" size-lg="6">
              <base-card title="Support Person">
                <ion-row>
                  <!-- Support Person Name -->
                  <ion-col>
                    <base-input label-text="Support Person Name">
                      <v-field name="support_person_name" v-slot="{ field }" v-model="user.profile.support_person_name">
                        <ion-input name="support_person_name" v-bind="field" placeholder="Support Person Name"
                          type="text" autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="support_person_name" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <!-- Support Person Number -->
                  <ion-col>
                    <base-input label-text="Support Person Number">
                      <v-field name="support_person_number" v-slot="{ field }"
                        v-model="user.profile.support_person_number">
                        <ion-input name="support_person_number" v-bind="field" placeholder="Support Person Number"
                          type="tel"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="support_person_number" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>

            <ion-col size="12" size-lg="6">
              <base-card title="Emergency Contact">
                <ion-row>
                  <!-- Emergency Contact Name -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Emergency Contact Name">
                      <v-field name="emergency_contact_name" v-slot="{ field }"
                        v-model="user.profile.emergency_contact_name">
                        <ion-input name="emergency_contact_name" v-bind="field" placeholder="Emergency Contact Name"
                          type="text" autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="emergency_contact_name" class="error-message"></v-error-message>
                  </ion-col>

                  <!-- Emergency Contact Relationship -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Emergency Contact Relationship">
                      <v-field name="emergency_contact_relationship" v-slot="{ field }"
                        v-model="user.profile.emergency_contact_relationship">
                        <ion-input name="emergency_contact_relationship" v-bind="field"
                          placeholder="Spouse, Mother, Father, etc." type="text" autocapitalize="words"
                          autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="emergency_contact_relationship" class="error-message"></v-error-message>
                  </ion-col>

                  <!-- Emergency Contact Number -->
                  <ion-col>
                    <base-input label-text="Emergency Contact Number">
                      <v-field name="emergency_contact_number" v-slot="{ field }"
                        v-model="user.profile.emergency_contact_number">
                        <ion-input name="emergency_contact_number" v-bind="field" placeholder="Emergency Contact Number"
                          type="tel"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="emergency_contact_number" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>
          </ion-row>

          <ion-row v-if="user.role && user.role !== 'baby' && user.role !== 'visitor'">
            <!-- OBGYN -->
            <ion-col size="12" size-lg="6">
              <base-card title="OBGYN">
                <ion-row>
                  <!-- OBGYN Name -->
                  <ion-col>
                    <base-input label-text="OBGYN Name">
                      <v-field name="obgyn_name" v-slot="{ field }" v-model="user.profile.obgyn_name">
                        <ion-input name="obgyn_name" v-bind="field" placeholder="OBGYN Name" type="text"
                          autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="obgyn_name" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <!-- OBGYN Number -->
                  <ion-col>
                    <base-input label-text="OBGYN Number">
                      <v-field name="obgyn_number" v-slot="{ field }" v-model="user.profile.obgyn_number">
                        <ion-input name="obgyn_number" v-bind="field" placeholder="OBGYN Number" type="tel"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="obgyn_number" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>

            <!-- Pediatrician -->
            <ion-col size="12" size-lg="6">
              <base-card title="Pediatrician">
                <ion-row>
                  <!-- Pediatrician Name -->
                  <ion-col>
                    <base-input label-text="Pediatrician Name">
                      <v-field name="pediatrician_name" v-slot="{ field }" v-model="user.profile.pediatrician_name">
                        <ion-input name="pediatrician_name" v-bind="field" placeholder="Pediatrician Name" type="text"
                          autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="pediatrician_name" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <!-- Pediatrician Number -->
                  <ion-col>
                    <base-input label-text="Pediatrician Number">
                      <v-field name="pediatrician_number" v-slot="{ field }" v-model="user.profile.pediatrician_number">
                        <ion-input name="pediatrician_number" v-bind="field" placeholder="Pediatrician Name"
                          type="tel"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="pediatrician_number" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>
          </ion-row>

          <ion-row v-if="user.role && user.role !== 'baby' && user.role !== 'visitor'">
            <!-- General Notes -->
            <ion-col size="12" size-lg="6">
              <base-card title="General Notes">
                <ion-row>
                  <!-- General Notes -->
                  <ion-col>
                    <base-input label-text="General Notes">
                      <v-field name="general_notes" v-slot="{ field }" v-model="user.general_notes">
                        <ion-textarea name="general_notes" rows="1" :auto-grow="true" v-bind="field"
                          placeholder="General Notes" autocapitalize autocorrect></ion-textarea>
                      </v-field>
                    </base-input>
                    <v-error-message name="general_notes" class="error-message"></v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button type="submit" expand="block" class="regular-button margin-bottom-16">
          Save</ion-button>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      user: {
        type: "guest",
        role: this.$route.query.role ? this.$route.query.role : null,
        profile: {},
      },
      required: yup.string().required(),
      emailRequired: yup.string().email().required(),
    };
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/users`,
        data: this.user,
      };

      console.log(config.data);

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then(async (response) => {
          this.user = response.data.user;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "New Guest Created",
            color: "secondary",
          });

          if (this.user.id && this.$route.query.relation_from_id) {
            // Create the relation
            await this.axios.post(`${process.env.VUE_APP_API}/relations/`, {
              user_id_from: this.$route.query.relation_from_id,
              user_id_to: this.user.id,
              type: "mother",
            });

            this.$router.replace(`/users/${this.user.id}`);
          } else {
            this.$router.replace(`/users/${this.user.id}`);
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>